import React from "react"
import styled from "@emotion/styled"
import { H2 } from "../Partials/Typography"
import { fontSize } from "../../styles/fonts/fontStyle"
import { deviceMinW } from "../../styles/mediaQueries"
import { spacer } from "../../styles/sizes"
import VerticalSpacer from "../Partials/VerticalSpacer"
import { Grid } from "../caseStudies/Components/Grid"
import ReactHtmlParser from "react-html-parser"
import RightContainer from "../Partials/RightContainer"

const Capabilities = ({ practiceAreas = [] }) => {
    return (
        <>
            <CapabilitiesContainer>
                <CapabilitiesRightContainer>
                    <ListContainer>
                        {practiceAreas.map((practiceArea, id) => {
                            return (
                                <PracticeAreaColumn key={id}>
                                    <H2>
                                        {ReactHtmlParser(practiceArea.category)}
                                    </H2>
                                    <VerticalSpacer size="m" />
                                    {practiceArea.items.map((text, id) => {
                                        return (
                                            <Li key={id}>
                                                {ReactHtmlParser(text.area)}
                                            </Li>
                                        )
                                    })}
                                </PracticeAreaColumn>
                            )
                        })}
                    </ListContainer>
                </CapabilitiesRightContainer>
            </CapabilitiesContainer>
        </>
    )
}
export default Capabilities

const CapabilitiesContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`

const CapabilitiesRightContainer = styled(RightContainer)`
    justify-content: flex-start !important;

    @media ${deviceMinW.laptop} {
        justify-content: flex-end;
        flex-basis: calc(100% * 4 / 5);
        max-width: calc(100% * 4 / 5);
        width: calc(100% * 4 / 5);
    }
`

const PracticeAreaColumn = styled.div`
    padding-bottom: ${spacer.l};

    @media ${deviceMinW.tablet} {
        padding-bottom: 0;
    }
`

const ListContainer = styled.ul`
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem;
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: grid;

    @media ${deviceMinW.tablet} {
        grid-template-columns: repeat(3, 1fr);
    }
`
const Li = styled.li`
    font-size: ${fontSize.longPrimer};
    padding-bottom: ${spacer.m};

    &:last-of-type {
        padding-bottom: 0;
    }

    @media ${deviceMinW.laptop} {
        font-size: ${fontSize.bodyCopy};
    }
`
