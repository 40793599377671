import styled from "@emotion/styled"
import { deviceMinW } from "../../../styles/mediaQueries"

export const Grid = styled.div`
    grid-gap: 4rem;
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;

    @media ${deviceMinW.tablet} {
        grid-gap: 8rem;
        grid-template-columns: 1fr 2fr;
    }
`
