import React from "react"
import { get } from "lodash"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { Hero } from "../components/Partials/Hero"
import Capabilities from "../components/PracticeAreas/Capabilities"
import useToggleFooterColor from "../hooks/useToggleFooterColor"
import Sidebar from "../components/Sidebar/Sidebar"
import AppContainer from "../styles/AppContainer"
import VerticalSpacer from "../components/Partials/VerticalSpacer"
import { colors } from "../styles/colors"

const PracticeAreas = ({ data }) => {
    const { heading, meta } = get(
        data,
        "allPracticeAreasJson.edges[0].node",
        {}
    )
    const { copy: headingCopy } = heading
    const { image: metaImage } = meta

    const { practiceAreas: areas = [] } = get(
        data,
        "allPracticeAreasJson.edges[0].node",
        {}
    )

    const doesCTAExist = !!headingCopy.slug

    useToggleFooterColor({ useDefault: true })

    return (
        <>
            <SEO
                title="Practice Areas | Descriptive"
                description="Our capabilities revolve around what we do best – product design and development."
                image={metaImage}
            />
            <AppContainer>
                <Sidebar copy="Practice Areas" fontColor={colors.silver} />
                <Hero heading={headingCopy} ctaExists={doesCTAExist} />
                <VerticalSpacer size="xxl" />
                <Capabilities practiceAreas={areas} />
            </AppContainer>
        </>
    )
}
export default PracticeAreas

export const query = graphql`
    query {
        allPracticeAreasJson {
            edges {
                node {
                    meta {
                        image {
                            src
                            alt
                        }
                    }
                    heading {
                        copy {
                            header
                        }
                    }
                    practiceAreas {
                        id
                        category
                        items {
                            id
                            area
                        }
                    }
                }
            }
        }
    }
`
